(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/middlewares/kibana-mw.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/middlewares/kibana-mw.js');
"use strict";


const CREATE_TEAM_REJECTED = 'createTeam/rejected';
const JOINED_INDIVIDUAL_REJECTED = 'putParticipation/rejected';
const FETCH_COMPETITION_REJECTED = 'fetchCompetition/rejected';
const FETCH_PLAYER_COMPETITION_REJECTED = 'fetchPlayerCompetitions/rejected';
const FETCH_COMPETITION_HIGHSCORE_REJECTED = 'fetchCompetitionHighscore/rejected';
const FETCH_PERSONAL_RESULT_REJECTED = 'fetchPersonalResultsAction/rejected';
const LEAVE_TEAM_REJECTED = 'leaveTeam/rejected';
const DELETE_TEAM_REJECTED = 'deleteTeam/rejected';
const logger = svs.core.log.getLogger('marketplace-competition:kibana-mw');

function resolveResponseError(error) {
  if (error !== null && error !== void 0 && error.responseJSON) {
    return error.responseJSON.error;
  }
  return error;
}

function handleAction(action, store) {
  let message;
  const error = resolveResponseError(action.error);
  switch (action.type) {
    case CREATE_TEAM_REJECTED:
      message = "Created team failed code: ".concat(error.code, " message: ").concat(error.message);
      break;
    case JOINED_INDIVIDUAL_REJECTED:
      message = "Joined competition failed ".concat(error.code, " message: ").concat(error.message);
      break;
    case FETCH_COMPETITION_REJECTED:
      message = "Fetch competition failed ".concat(error.code, " message: ").concat(error.message);
      break;
    case FETCH_COMPETITION_HIGHSCORE_REJECTED:
      message = "Fetch competition highscore failed ".concat(error.code, " message: ").concat(error.message);
      break;
    case FETCH_PLAYER_COMPETITION_REJECTED:
      message = "message: Fetch playercompetitions highscore failed ".concat(error.code, " message: ").concat(error.message);
      break;
    case FETCH_PERSONAL_RESULT_REJECTED:
      message = "fetch personal result failed";
      break;
    case LEAVE_TEAM_REJECTED:
      message = "leave team failed code: ".concat(error.code, " message: ").concat(error.message);
      break;
    case DELETE_TEAM_REJECTED:
      message = "delete team failed code: ".concat(error.code, " message: ").concat(error.message);
      break;
    default:
  }
  if (message) {
    logger.info("Mkp-competition: ".concat(message));
  }
}
const analyticsMw = store => next => action => {
  next(action);
  handleAction(action, store);
};
setGlobal('svs.components.marketplaceCompetition.services.middlewares.kibanaMw', analyticsMw);

 })(window);