(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/middlewares/middlewares.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/middlewares/middlewares.js');
"use strict";


const {
  analyticsMw,
  makeCompetitionMw,
  kibanaMw
} = svs.components.marketplaceCompetition.services.middlewares;
const competitionMiddlewares = [makeCompetitionMw, analyticsMw, kibanaMw];
setGlobal('svs.components.marketplaceCompetition.services.middlewares.competitionMiddlewares', competitionMiddlewares);

 })(window);