(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/middlewares/make-competition-mw.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/middlewares/make-competition-mw.js');
"use strict";


const {
  setPlayerCompetitionDraw,
  deletePlayerCompetitionDraw
} = svs.components.marketplaceCompetition.services.actions.playerCompetitionActions;
const {
  onEvent,
  eventNames
} = svs.components.lbUtils.onEvents;
const makeCompetitionMw = _ref => {
  let {
    dispatch
  } = _ref;
  return next => action => {
    onEvent(eventNames.PAYMENT_SUCCESS, _ref2 => {
      var _payable$isCompetitio;
      let {
        detail: {
          payable
        }
      } = _ref2;
      if ((_payable$isCompetitio = payable.isCompetition) !== null && _payable$isCompetitio !== void 0 && _payable$isCompetitio.call(payable)) {
        const bet = payable.getCompetitionBet();
        const competitionId = payable.competitionId[0];
        dispatch(setPlayerCompetitionDraw({
          drawNumber: bet.drawNumber,
          competitionId
        }));
      }
    });
    onEvent(eventNames.PAYMENT_CANCEL, _ref3 => {
      let {
        detail: {
          wager
        }
      } = _ref3;
      if (wager.competitionId) {
        const competitionId = wager.competitionId;
        dispatch(deletePlayerCompetitionDraw({
          drawNumber: wager.drawNumber,
          competitionId
        }));
      }
    });
    next(action);
  };
};
setGlobal('svs.components.marketplaceCompetition.services.middlewares.makeCompetitionMw', makeCompetitionMw);

 })(window);